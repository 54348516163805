import React from 'react'
import Layout from "../components/layout"
import {Link} from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import moment from 'moment'

import Bio from '../components/Bio'

import { StaticQuery, graphql } from "gatsby"

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    var currentYear = ""

    return (
        <StaticQuery
       query={graphql`
query IndexQuery {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 15) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date
          slug
        }
      }
    }
  }
}
`}
      render={data => (
      <Layout location={this.props.location}>
      <div>
        <Helmet title={siteTitle}>
            <link href="https://paul.querna.org/atom.xml" rel="alternate" title="Paul Querna's Journal" type="application/atom+xml" />
        </Helmet>

        <div className="page-header">
          <h1>Hi, I'm Paul Querna</h1>
        </div>

        <p>I like space, so I put a picture of Space on my website.</p>
        <p>I the CTO and co-founder of <a href="https://www.scaleft.com/">ScaleFT.</a></p>

        <h2>other stuff:</h2>
        <ul>
          <li><a href="/slides">slides</a></li>
          <li><a href="http://twitter.com/pquerna">twitter</a></li>
          <li><a href="http://github.com/pquerna">github</a></li>
          <li><a href="http://people.apache.org/~pquerna/">apache</a></li>
        </ul>

        <h2>contact:</h2>
        <ul>
          <li>email: me at this domain name.</li>
          <li>irc: pquerna on freenode/efnet.</li>
        </ul>

        {posts.map(post => {
          if (post.node.path !== '/404/') {
            const year = moment(post.node.frontmatter.date).format("YYYY");
            let yearMarker;
            if (currentYear != year) {
              currentYear = year
              yearMarker = (
                <div className="row">
                  <div className="col">
                    <h2 key={year}>{year}</h2>
                  </div>
                </div>
              )
            }

            const title = get(post, 'node.frontmatter.title') || post.node.frontmatter.slug
            const dateFmt = moment(post.node.frontmatter.date).format("MMMM Do, YYYY");

            return (
              <div key={post.node.fields.slug} className="container">
                {yearMarker}
                <div className="row">
                  <div className="col-md-11 offset-md-1">
                    <h4>
                      <Link to={post.node.fields.slug} >
                        {post.node.frontmatter.title}
                      </Link>
                    </h4>
                    <small>{dateFmt}</small>
                    <p dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
                  </div>
                </div>
              </div>
            )
          }
        })}

        <hr/>
        <div className="container">
        <div className="row">
                  <div className="col">
                    <h2>More</h2>
                  </div>
        </div>
        <div className="row">
          <div className="col-md-11 offset-md-1">
            <p>
              <a className="btn btn-outline-info" href="/archive" role="button">archived posts</a>  
            </p>
          </div>
        </div>
        </div>
        <Bio />
      </div>
      </Layout>
          )}
      />
    )
  }
}

BlogIndex.propTypes = {
  route: PropTypes.object,
}

export default BlogIndex
